<template>
  <form
    data-component-name="GetWhitePaperShort"
    autocomplete="off"
    data-id="getWhitePaperForm"
    :id="`getWhitePaperForm-${id}`"
    @input.passive="setFormFillingStart"
    @submit.once="submitForm"
  >
    <AppInput
      v-model.trim="fullname"
      type="text"
      :label="$t('Full_Name')"
      :error="errors.fullname"
      name="fullname"
      required
    />

    <AppInput
      v-model="email"
      type="email"
      :label="$t('Corporate_Email')"
      :error="errors.email"
      name="email"
      required
    />

    <Buttons.Regular
      data-id="submit-getWhitePaper"
      fill="solid"
      accent="purple"
      :disabled="!meta.valid || isSubmitting"
    >
      {{ $t('Submit') }}
      <SvgArrowForward />
    </Buttons.Regular>
  </form>
</template>

<script setup lang="ts">
import * as yup from 'yup';
import { storeToRefs } from 'pinia';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/yup';

// components
import AppInput from '~/components/redesign/AppInput.vue';
import Buttons from '~/components/redesign/Buttons';

// stores
import { useCommonStore } from '~/stores/common';

// utils
import pick from 'lodash.pick';
import getSubmittedFullName from '~/utils/getSubmittedFullName';

// constants
import REGEX from '~/constants/regex';
import { WHITE_PAPER } from '~/constants/api-endpoints';

// types
import type { Props } from './types';
import type { Countries, State } from '~/types/country';

// JSON
import countries from '~/data/countries.json';

// todo: refactor imports
import {submittedCookieValue} from '~/components/services/FormFillComponent';
import Cookie from '~/components/services/Cookie';
import {pushDataLayer} from '~/components/services/Analytics';

const props = withDefaults(defineProps<Props>(), { page: 'white_paper' });
const emit = defineEmits<{ loading: [value: boolean], submitted: [] }>();

const id = useId();
const { public: { api_app: baseURL } } = useRuntimeConfig();
const { ipInfo } = storeToRefs(useCommonStore());

const validationSchema = toTypedSchema(yup.object({
  fullname: yup
    .string()
    .required('This field is required')
    .default(getSubmittedFullName()),

  email: yup
    .string()
    .matches(REGEX.EMAIL, 'Invalid email address')
    .companyEmail()
    .email()
    .required('This field is required')
    .default(submittedCookieValue('email') || ''),
}));

const {
  errors,
  defineField,
  handleSubmit,
  resetForm,
  meta,
  isSubmitting,
} = useForm({ validationSchema });

const [fullname] = defineField('fullname');
const [email] = defineField('email');

const getLocationInfo = () => {
  const result: { country: string, state?: string } = { country: 'United States', state: 'Massachusetts' };

  if (ipInfo.value) {
    const { iso_code, state } = ipInfo.value;

    const foundCountry = (countries as Countries).find(country => country.isoCode === iso_code);

    if (foundCountry) {
      result.country = foundCountry.label;

      let foundState: State | undefined;

      if ('states' in foundCountry) {
        foundState = foundCountry.states.find(item => item.isoCode === state);
      }

      result.state = foundState?.label;
    }
  }

  return result;
};

const formFillingStart = ref<null | number>(null);
const resetFormFillingStart = () => {
  formFillingStart.value = null;
};
const setFormFillingStart = () => {
  if (formFillingStart.value !== null) return;
  formFillingStart.value = Date.now();
};
const getFormFillingSeconds = (): number | undefined => {
  if (formFillingStart.value === null) return;
  return (Date.now() - formFillingStart.value) / 1000;
};

const submitForm = handleSubmit(async ({ fullname, email }) => {
  const [firstname, lastname] = fullname.split(' ');

  const requestBody = {
    firstname,
    lastname: lastname || firstname,
    email,

    ...getLocationInfo(),

    start_time: formFillingStart.value?.toString(),
    of_form_duration: getFormFillingSeconds()?.toString(),

    cookie: Cookie.getCookieArray(),

    href: window.location.href,
    page: props.page,

    entry_page: localStorage.getItem('EntryPage') ?? '',
    referrer_page: localStorage.getItem('RefererPage') || '',
  };

  try {
    emit('loading', true);
    resetFormFillingStart();

    await $fetch(WHITE_PAPER, { method: 'POST', baseURL, body: requestBody });

    const existingCookie = Cookie.get('submitted_params');
    const cookieData = existingCookie ? JSON.parse(existingCookie) : {};
    const fieldsToUpdate = pick(requestBody, ['firstname', 'lastname', 'email'])

    Cookie.set(
      'submitted_params',
      JSON.stringify({
        ...cookieData,
        ...fieldsToUpdate,
      }),
    );

    emit('submitted');
    resetForm();

    Cookie.clearAfterSubmit();
    pushDataLayer(email, 'whitepaper');

    emit('submitted');
  } catch (error) {
    debug('catch', error);
  } finally {
    emit('loading', false);
  }
});
</script>

<style scoped lang="scss">
[data-component-name="GetWhitePaperShort"] {
  [data-component-name="AppInput"] {
    margin-bottom: 1.5rem;
  }

  [data-component-name="Buttons.Regular"] {
    width: 100%;
  }
}
</style>
